<template>
  <div>
    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-domain</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Dedicated company landing page</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Host all your events from your own internal or public branded company landing
          page allowing your colleagues or attendees to see all upcoming
          events.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-view-agenda</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Build professional event agendas</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Create sessions, add speakers and files in a seamless and interactive
          way.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-handshake</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Incorporate sponsors</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Promote your event sponsors with a strong branded
          experience.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-badge-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Invite speakers</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Invite your colleagues to speak at your event with a single click or create a
          profile for external speakers.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-cloud-upload</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Upload files</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Upload files or add links for the entire event or individual sessions so that
          everyone has what they need before or after the event.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-home-group</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Customised breakout experience</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Harness the power of collaboration and machine learning using Abardo's inbuilt
          breakout experience. Increasing connections and networking
          opportunities.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up"  v-if="inPerson">
      <v-list-item-icon>
        <v-icon>mdi-google-maps</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Google Maps with directions</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >In-person events - Maps and directions are built into your event page and
          calendar invitations are sent to your participants
          automatically.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up"  v-if="virtual">
      <v-list-item-icon>
        <v-icon>mdi-microsoft-teams</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Host with Microsoft Teams meeting</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Seamlessly create a virtual meeting for up to 300 participants. Using your
          organisations Office 365 account or use Abardo.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up"  v-if="virtual">
      <v-list-item-icon>
        <v-icon>mdi-microsoft-teams</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Host with Microsoft Teams live</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Seamlessly create a virtual broadcast for up to 20,000 participants. Using your
          organisations Office 365 account or use Abardo.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up"  v-if="virtual">
      <v-list-item-icon>
        <v-icon>mdi-video</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Host with a Zoom meeting</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Seamlessly create a virtual meeting for up to 500 participants. Using your
          organisations Zoom account.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Promote your event</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Share your event globally across social media platforms.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-badge-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Registration and data collection</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Simplify and customise your registration experience by designing your own data
          capture fields for attendees. Include your organisations data policies for
          compliance.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Calendar invitations</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Automatically send calendar invitations and reminders to your speakers and
          attendees.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-chart-bell-curve</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Insights</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Learn more from your experience with the power of insights, so you can track
          your progress with reliable data.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line data-aos="fade-up" >
      <v-list-item-icon>
        <v-icon>mdi-account-multiple-check</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Stay updated</v-list-item-title>
        <v-list-item-subtitle class="text-wrap"
          >Any event changes (dates, times and location) or cancellation will be sent
          automatically.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: ["virtual", "inPerson"],
  data: () => ({}),
};
</script>

<style scoped></style>
