<template>
  <div>
    <Hero
      title="Abardo"
      subTitle="Virtual events"
      description="Create unlimited digital internal or public events for your organisation."
      image="hero/virtual.png"
      :firstActionText="firstActionText"
      :firstActionUrl="firstActionUrl"
      :firstActionTarget="firstActionTarget"
      :palette="['#4b79cf', '#5176cf']"
      :angle="120"
      :dark="true"
      :transparency="true"
      :gradientAnimation="false"
      :fillHeight="true"
      animationName="fade-up"
      :virtual="true"
    />

    <v-container>
      <div class="my-9">
        <div class="text-h3 mb-6" data-aos="fade-in">Save countless hours</div>

        <div class="text-body-1 mb-6" data-aos="fade-in" data-aos-delay="100">
          With Abardo you can easily create a professional event page for your
          organisation. Seamlessly create a virtual meeting or broadcast and
          automatically send calendar invitations to all of your participants
          after registration.
        </div>

        <Features :virtual="true" />

        <Actions
          class="my-6"
          :firstActionText="firstActionText"
          :firstActionUrl="firstActionUrl"
          :firstActionTarget="firstActionTarget"
          :secondActionText="secondActionText"
          :secondActionUrl="secondActionUrl"
          :secondActionTarget="secondActionTarget"
          :dark="false"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import Features from "@/components/shared/Features";
import Hero from "@/components/shared/Hero";
import Actions from "@/components/shared/Actions";

export default {
  components: { Hero, Features, Actions },
  metaInfo: () => ({
    title:
      "Abardo - Virtual events - Create unlimited digital internal or public events for your organisation.",
  }),
  data() {
    return {
      firstActionText: "Try free now",
      firstActionUrl: "https://app.abardo.com/sign-in",
      firstActionTarget: "_blank"
    };
  },
};
</script>

<style scoped>
</style>

